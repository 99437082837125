// Balboa form component
.com-baforms-wrapper {
  width: 100%;
  .ba-form-page {
    padding: 0 !important;
    background-color: transparent;
    .ba-field-label-wrapper {
      display: none;
    }
    .show-label {
      .ba-field-label-wrapper {
        display: block;
      }
    }
    .ba-field-container select {
      color: $primary-colour;
      &.empty {
        color: $dark-grey;
      }
    }
    .upload-file-input {
      @include bp_max(mlarge) {
        background: transparent !important;
      }
    }
    .ba-form-signature-field .ba-signature-canvas, .ba-form-field-item .ba-form-products-cart .ba-form-product-quantity-cell input, .ba-form-page-navigation-title, .upload-file-btn, .upload-file-input, .ba-field-container select, .ba-field-container textarea[readonly], .ba-field-container textarea, .ba-field-container input[type="text"], .ba-field-container input[type="number"], .ba-field-container input[type="password"], .ba-field-container input[type="email"] {
      font-family: $primary-font;
      color: $primary-colour;
    }
    .ba-form-acceptance-field label.ba-form-checkbox > span, .ba-form-field-item .ba-form-checkbox-wrapper label.ba-form-checkbox > span {
      background-color: $off-white;
      &:hover {
        border-color: $primary-colour !important;
      }
    }
    .ba-form-acceptance-field label.ba-form-checkbox input[type="checkbox"]:checked + span, .ba-form-field-item .ba-form-checkbox-wrapper input[type="checkbox"]:checked + span {
      background: $primary-colour;
      border-color: $primary-colour !important;
    }
    .ba-form-acceptance-field .ba-form-acceptance-html p a {
      color: $primary-colour;
      &:hover {
        color: $white;
      }
    }
  }
}
#__lpform_1_icon {
  display: none;
}

// General form styles
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
