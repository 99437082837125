@import "_mixins";

.none {
  display: none;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}


.hide {
  &--medium-max {
    @include bp_max(medium) {
      display: none;
    }
  }
  &--large-max {
    @include bp_max(large) {
      display: none;
    }
  }
}