/* Page Specific Styling
================================================== */
.our-mission {
  padding-top: 0;
  .video-bg {
    margin-top: 6rem;
  }
}

.about-us {
  padding-top: 0;
  padding-bottom: 2rem;
}

.awards-carousel {
  position: relative;
  margin: 1rem 0 0 0;
  padding: 0;
  list-style: none;
  @include bp_max(medium) {
    margin: 1rem auto 0 auto;
  }

  .slick-slide {
    .hexagon {
      padding: 0.5rem 0;
      &__wrap {
        width: 230px;
      }
    }
  }

  .awards-vertical & {
    width: 230px;
    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
      height: 260px;
      background: rgb(241, 241, 241);
      background: linear-gradient(
        180deg,
        rgba(241, 241, 241, 0) 0%,
        rgba(241, 241, 241, 1) 100%
      );
    }
  }
  .awards-horizontal & {
    .slick-slide {
      .hexagon {
        img {
          z-index: 20;
        }
        &__wrap {
          &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            height: 72px;
            background: rgb(241, 241, 241);
            background: linear-gradient(
              180deg,
              rgba(241, 241, 241, 0) 0%,
              rgba(241, 241, 241, 1) 100%
            );
          }
        }
      }
    }
  }
}

section {
  .ba-gallery {
    padding-bottom: 0;
    .ba-caption {
      display: none;
    }
    .ba-gallery-items:hover .ba-image img {
      transform: scale(1.1) !important;
    }
    .ba-image img {
      transition: $global-transition;
    }
  }
}

#services {
  main {
    background: transparent;
  }
  .sidebar {
    background-color: $white;
    &:after {
      background-color: $white;
    }
    .list-style {
      a {
        color: $primary-colour;
        &:hover {
          color: $secondary-colour;
        }
      }
    }
  }
}

#about {
  main {
    padding-bottom: 0;
  }
}
