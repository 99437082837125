/* Components
================================================== */
[data-aos] {
  body[data-aos-easing="custom-easing"] &,
  &[data-aos][data-aos-easing="custom-easing"] {
    transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
}

/* Sticky Heading
================================================== */
/*.sticky-heading-wrap {
    position: sticky;
    z-index: 60;
    margin-bottom: 1.5rem;
}
.sticky-position {
    position: relative;
    div {
        position: sticky;
        top: -2px;
        width: 100%;
        height: 1px;
        background-color: $black;
    }
}*/
.sticky-heading,
.sub-heading {
  position: relative;
  margin-bottom: 1rem;
  /*background: $off-white;
    position: relative;
    z-index: 60;
    top: 0;
    margin-bottom: 1.5rem;
    color: $black;
    &.fixed {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
    }
    &.fixed.absolute {
        position: absolute;
    }*/

  p {
    display: flex;
    align-items: center;
    margin: 0;
    text-transform: uppercase;
    font-family: $quaternary-font;
    font-size: 0.9rem;
    font-weight: 400;
    &:before {
      content: "";
      width: 1.5rem;
      height: 3px;
      background: $base-font-color;
      margin-right: 0.8rem;
    }
  }
}

/* Social
================================================== */
.social {
  display: flex;
  align-items: center;
  p {
    display: flex;
  }
  a {
    display: inline-flex;
    align-items: center;
    padding: 0 0.3em;
    @include bp(medium) {
      padding: 0 0.5em;
    }
    &:hover {
      svg * {
        fill: $secondary-colour;
      }
    }
  }
  svg {
    max-width: 18px;
    max-height: 18px;
    width: 100%;
    height: 100%;
    * {
      transition: $global-transition;
    }
  }
}

/* Hexagons
================================================== */
.hexagon-list {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  left: -1.5rem;
  max-width: 1280px !important;
  width: 200%;
  margin: 4rem auto auto -2rem;
  @include bp(mlarge) {
    position: static;
    width: 160%;
    margin-left: auto;
  }
  @include bp(large) {
    position: absolute;
    width: 110%;
    right: -36%;
    left: auto;
    margin-top: 2rem;
  }
  /*@include bp(large) {
        width: 100%;
        right: -26%;
    }*/
  @include bp(xlarge) {
    width: 80%;
    right: -10%;
    margin-top: 0;
  }
  @include bp(xxlarge) {
    margin-top: -4rem;
  }
  .hexagon {
    @extend .small-3;
    min-width: 200px;
    margin-bottom: 0.9375rem;
    @include bp(smedium) {
      min-width: 230px;
    }
    &__wrap {
      min-width: 200px;
      @include bp(smedium) {
        min-width: 230px;
      }
    }
  }
}

.hexagon-grid {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  margin: 4rem 0 10rem -4rem;
  width: 540px;

  --s: 220px; /* size */
  --r: 0.9; /* ratio */
  /* clip-path */
  --h: 0.25;
  --v: 0.5;
  --hc: calc(clamp(0, var(--h), 0.5) * var(--s));
  --vc: calc(clamp(0, var(--v), 0.5) * var(--s) * var(--r));

  /*margin */
  --mv: 4px; /* vertical */
  --mh: calc(var(--mv) + (var(--s) - 2 * var(--hc)) / 1.8); /* horizontal */
  /* for the float*/
  --f: calc(2 * var(--s) * var(--r) + 4 * var(--mv) - 2 * var(--vc) - 2px);

  @include bp(medium) {
    width: 740px;
    margin-bottom: 20rem;
  }

  @include bp(mlarge) {
    --s: 260px;
    width: 920px;
  }
  @include bp(large) {
    --s: 280px;
    width: 1200px;
    margin-bottom: 6rem;
  }
  @include bp(xlarge) {
    position: absolute;
    top: 14rem;
    right: -220px;
    margin-top: 0;
    margin-right: 0;
  }
  @include bp(xxlarge) {
    --s: 300px;
    right: -150px;
  }

  &__wrap {
    font-size: 0; /* disable white space between inline block element */
    &::before {
      content: "";
      float: left;
      width: calc(var(--s) / 2 + var(--mh));
      height: 135%;
      shape-outside: repeating-linear-gradient(
        #0000 0 calc(var(--f) - 2px),
        #000 0 var(--f)
      );
    }

    & > div {
      display: inline-block;
      width: var(--s);
      height: calc(var(--s) * var(--r));
      margin: var(--mv) var(--mh);
      margin-bottom: calc(var(--mv) - var(--vc));
      background-color: #000;
      font-size: initial;
      clip-path: polygon(
        var(--hc) 0,
        calc(100% - var(--hc)) 0,
        100% var(--vc),
        100% calc(100% - var(--vc)),
        calc(100% - var(--hc)) 100%,
        var(--hc) 100%,
        0 calc(100% - var(--vc)),
        0 var(--vc)
      );
    }

    .hexagon {
      flex: none !important;
      min-width: auto !important;
      padding: 0 !important;
      &__wrap {
        clip-path: none !important;
        height: 100%;
        overflow: visible !important;
      }
    }
  }
}

.hexagon {
  @extend .columns;
  &__wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    clip-path: url(#hexagono);
    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    transition: $global-transition;
    * {
      transition: $global-transition;
    }
    &:before {
      content: "";
      display: block;
      position: relative;
      z-index: -1;
      padding-top: 86.66%;
    }
  }
  article {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    @extend %vca;
    width: 100%;
    height: 100%;
    padding: 3rem;
    text-align: center;
    font-size: 1.4rem;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    object-fit: cover;
  }
  &--text {
    .hexagon__wrap {
      background: $black;
    }
    article {
      p {
        margin: 0;
        color: $white;
        line-height: 150%;
      }
    }
    .title {
      margin-bottom: 0.5rem;
      font-family: $secondary-font;
      font-size: 2.8em;
      line-height: 100%;
    }
  }
  &--text-small {
    .title {
      font-size: 2em;
    }
    p {
      font-size: 0.9em;
    }
  }
  &--light-grey {
    .hexagon__wrap {
      background: $light-grey;
    }
  }
  &--highlight {
    .hexagon__wrap {
      background: $secondary-colour;
    }
  }
  &--white {
    .hexagon__wrap {
      background: $white;
    }
    * {
      color: $base-font-color;
    }
    &.hexagon--button {
      .hexagon__wrap {
        &:hover {
          background-color: $primary-colour;
          p {
            color: $secondary-colour;
          }
        }
      }
    }
  }
  &--contain-image {
    img {
      height: auto;
      padding: 2rem;
    }
  }
  &--offset {
    margin-left: 6.5%;
    @include bp(smedium) {
      margin-left: 12.5%;
    }
  }
  &--add-padding {
    img {
      padding: 3rem;
    }
  }
  &--hide-mobile {
    @include bp_max(large) {
      display: none !important;
    }
  }
}

/* Box Content
================================================== */
.boxContent,
.bannerContent {
  .row {
    padding: 6rem 4rem;
    @include bp(large) {
      padding: 8rem;
    }
  }
  .sticky-heading {
    margin-bottom: 0.5rem;
  }
  &--black {
    .row {
      background: $primary-colour;
    }
    * {
      color: $white;
    }
    .sticky-heading {
      p:before {
        background: $white;
      }
    }
    .button {
      background-color: $off-white;
      color: $primary-colour;
      &:hover {
        color: $secondary-colour;
        background-color: $primary-colour;
        border-color: $secondary-colour;
      }
    }
  }
  &--white {
    .row {
      background-color: $white;
    }
  }
  &--off-white {
    .row {
      background-color: $off-white;
    }
  }
  &--grey {
    .row {
      background-color: $grey;
    }
  }
  &--colour {
    .row {
      background-color: $secondary-colour;
    }
  }
  &--small {
    .row {
      padding: 2rem;
    }
  }
  &--align-center {
    .wf-columns {
      align-items: center;
    }
  }
  &--focused-service {
    padding: 0;
  }
  &--example {
    padding: 1rem 0;
    .row {
      background-color: $off-white;
    }
    h3 {
      margin-bottom: 1rem;
      font-size: 1.2rem;
      font-family: $primary-font;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  &--seetickets {
    padding-top: 0;
    z-index: 10;
    &-offset {
      margin-top: -6rem;
    }
    .row {
      background: $seetickets;
    }
    * {
      color: $white;
    }
    .sticky-heading {
      p:before {
        background: $white;
      }
    }
  }
}

/* Card
================================================== */
.card {
  @extend .shadow-style-1;
  &__title {
    position: relative;
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    justify-content: center;
    background-color: $primary-colour;
    * {
      margin: 0;
      color: $white;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &::before {
      background: url("#{$image-directory}hexagon-box-bottom-left.svg")
        no-repeat 0% 100%;
    }
    &::after {
      background: url("#{$image-directory}hexagon-box-top-right.svg") no-repeat
        100% 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 2rem;
    }
  }
  &__content {
    padding: 2rem;
    background: $white;
  }
}

/* List Style
================================================== */
.list-style,
.sidebar .nav {
  padding: 0;
  list-style: none;
  li {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem 0;
    font-size: 0.8rem;
    @include bp(mlarge) {
      font-size: 1rem;
    }
    * {
      margin: 0;
    }
  }
  .hexagon {
    flex: none;
    max-width: 56px;
    min-width: 56px;
    &__wrap {
      background-color: $light-grey;
    }
  }
}
.list-style {
  &--base {
    & > li {
      align-items: baseline;
      * {
        margin: revert;
      }
    }
  }
  &--links {
    a {
      color: $primary-colour;
      font-family: $quaternary-font;
      text-transform: uppercase;
      &:hover {
        color: $secondary-colour;
      }
    }
  }
  &--tick {
    .hexagon {
      display: none;
    }
    svg {
      width: 32px;
      min-width: 32px;
      height: auto;
      margin-right: 1rem;
    }
  }
  &--narrow {
    li {
      padding: 0.5rem 0 !important;
    }
  }
}

/* Accordion
================================================== */
.accordion-toggle {
  list-style: none;
  padding: 0;
  li {
    margin: 0.5em 0;
    cursor: pointer;
    &:hover {
      .hexagon__wrap {
        background-color: $secondary-colour;
      }
      article {
        div {
          background-color: $white;
        }
      }
    }
    &.show {
      .hexagon__wrap {
        background-color: $secondary-colour;
      }
      article {
        div {
          background-color: $white;
          transform: rotate(90deg);
          &.horizontal {
            opacity: 0;
          }
        }
      }
    }
  }
  .inner {
    display: none;
    overflow: hidden;
    margin: 2rem 2rem 3rem 2rem;
    p {
      margin-top: 0;
    }
  }
  .toggle {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 !important;
    padding: 0.75rem 0;
    font-family: $base-heading-font-family;
    line-height: $base-heading-line-height;
    font-weight: $base-heading-font-weight;
    span {
      @include bp(large) {
        display: inline-flex;
        max-width: 60%;
      }
      @include bp(xxlarge) {
        max-width: 100%;
      }
    }
  }
  .hexagon {
    display: inline-flex;
    flex: none;
    max-width: 80px;
    width: 100%;
    padding: 0 1.5rem 0 0;
    &__wrap {
      background-color: $light-grey;
    }
    article {
      padding: 0;
      div {
        position: absolute;
        background-color: $primary-colour;
        top: 50%;
        left: 50%;
        transition: $global-transition;
        transform: rotate(-90deg);
      }
    }
  }
  .horizontal {
    width: 22px;
    height: 2px;
    margin-top: -0.5px;
    margin-left: -10.5px;
    opacity: 1;
  }
  .vertical {
    width: 2px;
    height: 22px;
    margin-top: -10.5px;
    margin-left: -0.5px;
  }

  // Smaller component
  &--small {
    @include bp(large) {
      .toggle {
        font-size: 1.2rem;
      }
      .hexagon {
        max-width: 64px;
      }
      .horizontal {
        width: 16px;
        height: 2px;
        margin-top: -0.5px;
        margin-left: -7.5px;
      }
      .vertical {
        width: 2px;
        height: 16px;
        margin-top: -7.5px;
        margin-left: -0.5px;
      }
    }
  }
}

/* Slider
================================================== */
.slick-slider {
  &:hover {
    .slick-next {
      opacity: 1;
    }
  }
}
.slick-prev {
  display: none !important;
}
.slick-next {
  position: absolute;
  z-index: 20;
  right: 0;
  bottom: -2rem;
  width: 140px;
  margin-left: auto;
  transition: $global-transition;
  @include bp(xlarge) {
    opacity: 0;
  }
  .hexagon__wrap {
    cursor: pointer;
  }
  article {
    padding: 1rem;
  }
  p {
    font-family: $tertiary-font;
    font-style: italic;
    font-size: 1rem;
  }
}

/* Icon Links
================================================== */
.icon-links {
  &--intro {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    .icon-link {
      flex: 0 0 100%;
      max-width: 100%;
      min-width: 0;
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      @include bp(mlarge) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include bp(large) {
        flex: 0 0 100%;
        max-width: 100%;
      }
      @include bp(xlarge) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
.icon-link {
  a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 2rem;
    &:hover {
      * {
        color: $secondary-colour;
      }
    }
    * {
      color: $black;
      transition: $global-transition;
    }
  }
  figure {
    flex: 0 0 110px;
    max-width: 110px;
    margin: 0;
    text-align: center;
  }
  img {
    display: block;
    max-width: 96px;
    width: 100%;
    max-height: 72px;
  }
  h2 {
    margin: 0;
    font-size: 1.2rem;
  }
}
