// Images
img {
  max-width: 100%;
  height: auto;
}

// Video
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video-bg {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  video {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
    position: absolute;
    /*top: 0;
    right: 0;
    bottom: 0;
    left: 0;*/
    * {
      width: 100%;
    }
  }

  &--overlay {
    &:before {
      content: '';
      object-fit: cover;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 40;
      background: url('#{$image-directory}white-hex-bottom-left.svg') no-repeat 0 100%;
    }
  }

  &--height {
    padding-bottom: 0;
    height: 480px;
    @include bp(mlarge) {
      height: 640px;
    }
  }

  &--top {
    video {
      top: 0;
    }
  }
}