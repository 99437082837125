/* Header
================================================== */
header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 90;
  &.fixed {
    position: fixed;
    top: -100%;
  }
  @include bp(medium) {
    position: fixed;
    top: 0;
  }

  .shrink & {
    .header__main {
      padding: 0.5rem 0;
    }
    .header__logo {
      img {
        max-width: 200px;
      }
    }
  }
  &.show {
    top: 0;
    transition: $global-transition;
  }
  &.hide {
    top: -100%;
    transition: $global-transition;
  }
}

.header {
  &-graphic {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    width: 920px;
    min-width: 640px;
  }
  &__top {
    background: $black;
    * {
      color: $white;
    }
    .row {
      align-items: center;
      width: 100%;
      min-height: 3rem;
    }
    p {
      margin: 0;
    }
    .telephone-number {
      @include bp_max(smedium) {
        font-size: 0.8rem;
      }
    }
    .social {
      justify-content: flex-end;
      height: 100%;
    }
  }
  &-top {
    &__center {
      text-align: center;
      @include bp_max(large) {
        display: none;
      }
      strong {
        transition: $global-transition;
      }
      a:hover {
        color: $white;
        strong {
          color: $secondary-colour;
        }
      }
    }
    &__right {
      text-align: right;
    }
  }
  &__main {
    padding: 1rem 0;
    background-color: $white;
    transition: $global-transition;
    .row {
      justify-content: space-between;
      align-items: center;
    }
  }
  &__logo {
    img {
      width: 100%;
      max-width: 280px;
      height: auto;
      float: left;
      transition: $global-transition;
    }
  }
}
