h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $base-heading-font-family;
  line-height: $base-heading-line-height;
  font-weight: $base-heading-font-weight;
  margin: 0.5rem 0 3rem;
  em {
    font-family: $tertiary-font;
    font-weight: 400;
  }
}
h1,
.h1 {
  font-size: $base-h1-font-size;
  @include bp_max(medium) {
    font-size: $base-h1-font-size-mobile;
  }
}
h2,
.h2 {
  font-size: $base-h2-font-size;
  @include bp_max(medium) {
    font-size: $base-h2-font-size-mobile;
  }
}
h3,
.h3 {
  font-size: $base-h3-font-size;
  @include bp_max(medium) {
    font-size: $base-h3-font-size-mobile;
  }
}
h4,
.h4 {
  font-size: $base-h4-font-size;
  @include bp_max(medium) {
    font-size: $base-h4-font-size-mobile;
  }
}
h5,
.h5 {
  font-size: $base-h5-font-size;
  @include bp_max(medium) {
    font-size: $base-h5-font-size-mobile;
  }
}
h6,
.h6 {
  font-size: $base-h6-font-size;
  @include bp_max(medium) {
    font-size: $base-h6-font-size-mobile;
  }
}
