/* Sidebar
================================================== */
main.has-sidebar {
  position: relative;
  overflow: visible;
  z-index: 10;
  margin-top: -16rem;
  padding: 18rem 0 0 0;
  background: $grey;
  @include bp(large) {
    padding-top: 22rem;
  }
  .main-content {
    padding-bottom: 6rem;
    @include bp(large) {
      order: 2;
    }
  }
  a {
    color: $primary-colour;
    &:hover {
      color: $secondary-colour;
    }
  }
}
.sidebar {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: $off-white;
  @include bp(large) {
    order: 1;
    padding-top: 0;
    padding-right: 3rem;
  }
  &:after {
    @include bp(large) {
      content: "";
      position: absolute;
      z-index: -1;
      top: -6rem;
      right: 0;
      bottom: 0;
      width: 200%;
      background-color: $off-white;
    }
  }
  &__wrap {
    @include bp(large) {
      position: sticky;
      top: 180px;
    }
    @media screen and (max-height: 1080px) {
      position: static;
    }
  }
  h4 {
    @include bp(large) {
      font-size: 1.6rem;
    }
  }
  .moduletable {
    margin-bottom: 10rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0.5rem 0 2rem;
    }
    p {
      margin-top: 0;
    }
  }
  .list-style {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }
  }
  .nav {
    flex-flow: column;
    li {
      text-align: left;
    }
    a {
      display: flex;
      align-items: center;
      padding: 0;
    }
    ul {
      display: none !important;
    }
  }
}

.hexagon-stats {
  .hexagon {
    margin-bottom: 2rem;
    &__wrap {
      max-width: 240px;
      margin: auto;
    }
  }
}
