.button,
button,
input[type="submit"],
input[type="reset"],
input[type="button"],
.ba-btn,
.button-mail-wrap a,
.ba-form-submit-btn,
.upload-file-btn {
  position: relative;
  overflow: hidden;
  z-index: 0;
  display: inline-flex;
  flex-direction: column;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 1rem 2rem;
  background: transparent;
  border: 2px solid $black;
  color: $black;
  font-size: 0.9rem;
  font-weight: 500;
  text-decoration: none;
  line-height: normal;
  font-family: $quaternary-font;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  border-radius: 50px;
  @include transition($global-transition);
  -webkit-appearance: none;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  &:hover {
    background-color: $black;
    color: $secondary-colour;
  }

  /*&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    z-index: -2;
    opacity: 0;
    background-color: $black;
    transition: $global-transition;
    -webkit-transform: translate3d(0, 0, 0);
  }
  &:hover {
    color: $white;
    border: 2px solid $black;
    &:before {
      left: 0;
      right: 0;
      opacity: 1;
      transition: $global-transition;
    }
  }

  &:focus {
    &:before {
      transition: $global-transition;
      left:0;
      right:0;
      opacity:1;
    }
  }
  
  &:active {
    border: 2px solid $secondary-colour;
  }
  &:visited {
    color: $black;
  }*/
}

.ba-form-page {
  .ba-form-submit-btn,
  .upload-file-btn {
    display: inline-flex !important;
    flex-direction: column !important;
    margin: 0 !important;
    padding: 1rem 2rem !important;
    background: transparent !important;
    border-radius: 50px !important;
    border: 2px solid $black !important;
    color: $black !important;
    font-family: $quaternary-font !important;
    text-transform: uppercase !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    line-height: normal !important;
    text-align: center !important;
    &:hover {
      background-color: $black !important;
      color: $secondary-colour !important;
    }
  }
  .upload-file-btn {
    margin-top: 2rem !important;
  }
}

.button {
  &--full-width {
    width: 100%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center;
  }
  &--white {
    background: $off-white;
  }
  &--outline {
    background: transparent;
    border: 2px solid $primary-colour;
    &:hover {
      background-color: $primary-colour;
    }
  }
}

.ba-btn {
  padding: 1rem 2rem !important;
  border: 2px solid $black !important;
  &:hover {
    color: $secondary-colour !important;
  }
}

/* Fix for odd Mozilla border & padding issues */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button-wrap-center {
  text-align: center;
}
