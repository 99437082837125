*, *:before, *:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  @include bp(small) {
    font-size: 1.1rem;
  }
}

body { 
  margin: 0; 
}

// Hide visually, but make it readable for screenreaders
.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Code
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}