// 1. File Paths
// --------------
$public-path: "../";
$image-directory: "../images/";
$font-directory: "../fonts/";

// 2. Width
// --------------
$global-width: 1640px;

// 3. Fonts
// --------------
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&family=Work+Sans:wght@500&display=swap");

@font-face {
  font-family: "gilroylight";
  src: url("#{$font-directory}gilroy-light-webfont.woff2") format("woff2"),
    url("#{$font-directory}gilroy-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "gilroyextrabold";
  src: url("#{$font-directory}gilroy-extrabold-webfont.woff2") format("woff2"),
    url("#{$font-directory}gilroy-extrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

$primary-font: "gilroylight";
$secondary-font: "gilroyextrabold";
$tertiary-font: "Playfair Display", serif;
$quaternary-font: "Work Sans", sans-serif;

// 4. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
$primary-colour: #222222;
$secondary-colour: #f1c506;
/// @type Map
$foundation-palette: (
  primary: $primary-colour,
  secondary: $secondary-colour,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #e5e5e5 !default;
$medium-grey: #cacaca !default;
$grey: #d9d9d9 !default;
$dark-grey: #8a8a8a !default;
$black: #222222 !default;
$white: #ffffff !default;
$off-white: #f1f1f1 !default;

$body-background: $white !default;
$body-font-color: $black !default;

$seetickets: #1f9395;

// 5. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 100% !default;
$base-line-height: 1.8 !default;
$base-font-color: $black !default;
$base-font-weight: 400 !default;
$base-background-color: $off-white !default;

// 6. Headings
// --------------
$base-heading-font-family: $secondary-font !default;
$base-heading-line-height: 1.2 !default;
$base-heading-font-weight: normal !default;
$base-h1-font-size: 3.4rem !default;
$base-h2-font-size: 2.8rem !default;
$base-h3-font-size: 2.4rem !default;
$base-h4-font-size: 1.8rem !default;
$base-h5-font-size: 1.6rem !default;
$base-h6-font-size: 1.4rem !default;
$base-h1-font-size-mobile: 3rem !default;
$base-h2-font-size-mobile: 2.4rem !default;
$base-h3-font-size-mobile: 2rem !default;
$base-h4-font-size-mobile: 1.6rem !default;
$base-h5-font-size-mobile: 1.4rem !default;
$base-h6-font-size-mobile: 1.2rem !default;

// 7. Breakpoints
// --------------
//  Mixin scss classes e.g. @include bp(mlarge)
//  & Foundation breakpoint html class names e.g. class="small-6 xlarge-12"
$breakpoints: (
  small: 0,
  smedium: 480px,
  medium: 576px,
  mlarge: 768px,
  large: 992px,
  xlarge: 1280px,
  xxlarge: $global-width,
  xxxlarge: 1800px,
);
$breakpoint-classes: (small smedium medium mlarge large xlarge xxlarge xxlarge);

// 8. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;

// 9. Global
// --------------
$global-lineheight: 1.5;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);

// 10. Transitions
// --------------
$global-transition: 600ms cubic-bezier(0.23, 1, 0.32, 1);
