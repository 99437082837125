/* News
================================================== */
.newslist {
    .latestnews-items {
        flex-flow: column !important;
        align-items: normal !important;
        -webkit-justify-content: normal !important;
        -ms-flex-pack: normal !important;
        justify-content: normal !important;
        overflow: visible !important;
        @include bp(medium) {
            flex-flow: row wrap !important;
        }
    }
    .latestnews-item {
        flex: 1;
        @extend .columns;
        @extend .small-12;
        padding: 0 1rem 2rem !important;
        @include bp(medium) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include bp(large) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        & > a {
            display: block;
            height: 100%;
            &:hover {
                .detail_category, .detail_tags {
                    opacity: 1;
                }
                .link {
                    a {
                        background-color: $primary-colour;
                        span {
                            color: $white;
                        }
                    }
                }
            }
        }
        .newsSection & {
            border: 0;
        }
        &.active {
            opacity: 1 !important;
        }
    }
    .newshead {
        float: none !important;
        margin: 0 !important;
    }
    /*.picture {
        min-height: 0;
        width: 100%;
        max-width: 100% !important;
        max-height: 100% !important;
        @include bp(large) {
            min-height: 140px;
        }
        @include bp(xxlarge) {
            min-height: 190px;
        }
        a {
            display: block !important;
        }
        img {
            width: 100%;
        }
    }*/
    .innerpicture {
        width: 100%;
        &:after {
            content: '';
            display: block;
            padding-bottom: 60%;
        }
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .news {
        position: relative !important;
        height: 100% !important;
        margin: 0 !important;
        padding: 0rem !important;
        background-color: $white !important;
        border: none !important;
    }
    .newstitle {
        font-size: 1.6rem;
        font-family: $primary-font !important;
        font-weight: normal !important;
        line-height: $base-heading-line-height !important;
        margin: 0 0 0.5em 0 !important;
        a {
            color: $primary-colour;
            &:hover {
            color: $secondary-colour;
            }
        }
    }
    .newsinfo {
        padding: 1.5rem 1rem 4rem !important;
        * {
            color: $primary-colour;
        }
    }
    .newsintro {
        line-height: $base-line-height !important;
    }
    .detail_category, .detail_tags {
        opacity: 1;
        display: inline-flex;
        padding: 0.4rem 0.8rem;
        border-radius: 50px;
        background: $off-white;
        font-family: $primary-font;
        font-weight: 700;
        font-size: 0.8rem;
        color: $primary-colour;
        transition: $global-transition;
        @include bp(medium) {
            opacity: 0.25;
        }
    }
    /*.detail_category {
        background: $secondary-colour;
    }
    .detail_tags {
        background: $primary-colour;
    }*/
    .detail_date {
        display: flex;
        align-items: center;
        margin: 0 0 0.25rem 0;
        text-transform: uppercase;
        font-family: $quaternary-font;
        font-size: 0.6rem;
        font-weight: 400;
    }
    .delimiter {
        display: none;
    }
    .link {
        width: 100% !important;
        margin-top: 1.5em !important;
        a {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 0.5rem 0;
            background-color: $grey;
            color: $primary-colour;
            text-align: center;
            font-family: $quaternary-font;
            text-transform: uppercase;
            font-weight: 400;
        }
        span {
            transition: $global-transition;
        }
    }
    &--full-width {
        .latestnews-item {
            @include bp(medium) {
                flex: 0 0 100% !important;
                max-width: 100% !important;
            }
        }
    }
    &--full-details {
        .latestnews-item {
            .newsinfo {
                padding: 1.5rem 1rem !important;
            }
            ul, p {
                margin-bottom: 1rem !important;
            }
        }
    }
  }
  
  
  
  /* News Page
  ================================================== */
  #news-item, #job {
    padding-top: 0;
  }
  .news-item-article {
    padding-top: 16rem;
    background-color: $grey;
    .published {
        margin: 0;
        font-family: $quaternary-font;
        text-transform: uppercase;
        font-weight: 400;
    }
    .com-content-image, .item-page {
        max-width: 960px;
        width: 100%;
        margin: auto;
    }
    .com-content-image {
        margin-bottom: 0;
        figure {
            position: relative;
            margin: 0;
            /*&:after {
                content: '';
                display: block;
                padding-bottom: 40%;
            }*/
        }
        img {
            display: block;
            //position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .item-page {
        padding: 1.5em;
        background-color: $white;
        @include bp(medium) {
            padding: 3em;
        }
    }
    h1 {
        font-family: $tertiary-font;
        font-style: italic;
        @include bp_max(medium) {
            font-size: $base-h2-font-size;
        }
    }
    .article-info.muted {
        display: block;
        color: $primary-colour;
        margin-bottom: 2em;
    }
  }

  .sharethis-inline-share-buttons {
        margin-top: 4rem;
  }
  
  .article-info.muted {
        display: none;
  }


/* News Carousel
================================================== */
.news-carousel {
    .slick-track {
        height: 100%;
    }
    .newslist {
        .latestnews-item {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
    } 
}

/* Testimonials
================================================== */
#testimonials {
    overflow: hidden;
    margin: 0;
    .sticky-heading-wrap {
        position: relative;
        z-index: 20;
    }
    h2 {
        margin-bottom: 6rem;
    }
    .latestnews-items, .innernews, .news, .picture {
        overflow: visible !important;
    }
    .latestnews-items {
        display: block !important;
    }
    .slick-list {
        overflow: visible;
    }
    .slick-prev {
        display: none !important;
    }
    .slick-next {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 95%;
        margin-left: auto;
        @include bp(mlarge) {
            bottom: 105%;
        }
        @include bp(large) {
            position: relative;
            bottom: 5rem;
        }
        @include bp(xlarge) {
            margin-right: 8.3333%;
        }
    }
    .latestnews-item {
        z-index: 10 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        .newstitle {
            display: none;
        }
        .news {
            background-color: transparent !important;
        }
        .innernews {
            display: flex;
            flex-wrap: wrap;
        }
        .newsinfo {
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0;
            padding: 0 !important;
            @include bp(mlarge) {
              flex: 0 0 50%;
              max-width: 50%;
              margin-left: 8.33333%;
            }
            @include bp(xlarge) {
                margin-left: 0;
            }
        }
        .newsintro {
            margin-bottom: 2rem;
            font-style: italic;
            font-size: 1.1rem;
            p {
                margin-bottom: 1rem;
            }
        }
        .delimiter {
            display: none;
        }
        .item_details {
            max-width: 75%;
        }
        .testimonial {
            &__name, &__position, &__company {
                display: block;
                font-size: 1rem;
            }
            &__name {
                font-family: $secondary-font;
                font-size: 1.1rem;
            }
            &__position, &__company {
                display: inline-flex;
                font-family: $tertiary-font;
                font-style: italic;
            }
        }
        .newshead {
            float: none;
            flex: 0 0 100%;
            max-width: 100%;
            margin: 0 0 2rem 0 !important;
            @include bp(mlarge) {
                flex: 0 0 41.66667%;
                max-width: 41.66667%;
                margin: 0 !important;
            }
        }
        .picture {
            position: relative;
            max-width: 100%;
            max-height: 100%;
            height: auto;
            min-height: 100%;
        }
        .innerpicture {
            position: relative;
            overflow: hidden;
            width: 100%;
            max-width: 280px;
            margin-left: 20%;
            background: $black;
            clip-path: url(#hexagono);
            clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
            @include bp(large) {
                margin-left: 35%;
            }
            &:before {
                content: "";
                display: block;
                position: relative;
                z-index: -1;
                padding-top: 86.66%;
            }
            &:after {
                display: none !important;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 60%;
                height: 100%;
                max-height: 120px;
                object-fit: contain;
                filter: brightness(0) invert(1);
            }
        }
        .hexagon {
            &--quote {
                position: absolute;
                top: -2rem;
                left: 0;
                z-index: -1;
                max-width: 200px;
                width: 100%;
                @include bp(large) {
                    left: 15%;
                }
                .hexagon__wrap {
                    background-color: $light-grey;
                }
                img {
                    max-width: 92px !important;
                    padding: 0.5rem;
                }
            }
        }
    }
}


/* Case Study Testimonial
================================================== */
#testimonials {
    &.testimonials {
        &--case-study {
            .picture {
                .hexagon {
                    position: static;
                }
            }
            .innerpicture {
                display: none;
            }
            .newshead {
                @include bp(mlarge) {
                    flex: 0 0 33.333%;
                    max-width: 33.333%;
                }
            }
            .newsinfo {
                @include bp(mlarge) {
                    flex: 0 0 66.666%;
                    max-width: 66.666%;
                    margin-left: 0;
                }
            }
            .hexagon--quote {
                top: 0;
            }
        }
    }
}