/* Main
================================================== */
body {
  padding-top: 10rem;
}
main {
  overflow: hidden;
  padding: 6rem 0;
}

.label-warning {
  display: none;
}

.typewriter {
  display: inline-flex;
  min-width: 350px;
  span {
    border-right: 0.05em solid;
    animation: caret 1s steps(1) infinite;
  }
}
@keyframes caret {
  50% {
    border-color: transparent;
  }
}

.title {
  &--width {
    &-small {
      max-width: 320px;
    }
    &-smedium {
      max-width: 440px;
    }
    &-medium {
      max-width: 576px;
    }
    &-mlarge {
      max-width: 768px;
    }
    &-large {
      max-width: 992px;
    }
  }
  &--margin-bottom {
    margin-bottom: 1rem;
  }
  &--two-lines {
    em {
      @include bp(large) {
        display: block;
      }
    }
  }
}

.wf-columns-stack-medium > .wf-column {
  @include bp_max(large) {
    width: 100%;
    flex: auto !important;
  }
}

.shadow-style-1 {
  @include box-shadow(0 0 50px 0 $off-white);
}

.border-radius {
  border-radius: 24px;
  overflow: hidden;
}
