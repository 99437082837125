/* Project Nav
================================================== */
.bannerNav {
    display: flex;
    justify-content: flex-end;
    background: $off-white;
    .nav, .pagenav {
      li {
        flex-grow: 1;
        flex-basis: initial;
        @include bp(smedium) {
          flex-grow: 0;
        }
      }
      a {
        padding: 1rem;
        color: $white !important;
        transition: $global-transition;
        @include bp(smedium) {
          padding: 1.2rem 3.6rem;
        }
      }
    }
    .content-links {
      @include bp_max(smedium) {
          flex: 0 0 33.3333333333%;
          max-width: 33.3333333333%;
      }
      a {
        color: $secondary-colour !important;
        background-color: darken($off-white, 4%);
        &:hover {
          background-color: darken($off-white, 8%);
        }
      }
    }
    .pagenav {
      justify-content: flex-end;
      @include bp_max(smedium) {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
      }
      svg {
        width: inherit;
        height: inherit;
        margin: 0;
        margin-bottom: 2px;
      }
    }
    .previous, .next {
      @include bp_max(smedium) {
          flex: 0 0 50%;
          max-width: 50%;
      }
      a {
        display: flex;
        justify-content: center;
      }
      .icon-chevron-left, .icon-chevron-right {
        display: none;
      }
      span {
        @include bp_max(smedium) {
          text-overflow: ellipsis;
          overflow:hidden;
          white-space:nowrap;
          width: 62px;
        }
      }
    }
    .previous {
      a {
        background-color: lighten($secondary-colour, 6%);
        &:hover {
          background-color: lighten($secondary-colour, 10%);
        }
      }
      svg {
        @include rotate(180deg);
        margin-right: 0.6rem;
      }
    }
    .next {
      a {
        background-color: $secondary-colour;
        &:hover {
          background-color: darken($secondary-colour, 4%);
        }
      }
      svg {
        margin-left: 0.6rem;
      }
    }
}
.tooltip {
  display: none !important;
}
  
/* Projects
================================================== */
.slickWrap {
  @include bp(large) {
    position: relative;
    left: 26rem;
  }
}
.draggable {
  * {
    cursor: move !important;
    cursor: grab !important;
    cursor: -moz-grab !important;
    cursor: -webkit-grab !important;
  }
  &:active {
    * {
    cursor: grabbing !important;
    cursor: -moz-grabbing !important;
    cursor: -webkit-grabbing !important;
    }
  }
}
.case-studies {
  &--section {
    .caseStudyWrap {
      @extend .columns;
      @extend .small-12;
      @include bp(medium) {
          flex: 0 0 50%;
          max-width: 50%;
      }
    }
  }
  &--full-section {
    .caseStudyWrap {
      @extend .columns;
      @extend .small-12;
      @include bp(medium) {
          flex: 0 0 50%;
          max-width: 50%;
      }
      @include bp(large) {
          flex: 0 0 33.333%;
          max-width: 33.333%;
      }
    }
  }
  &--carousel {
    .caseStudyWrap {
      margin: 0 0.5rem;
    }
  }
}
.caseStudy {
  position: relative;
  display: block;
  overflow: hidden;
  height: 21rem;
  margin-bottom: 2rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @include bp(medium) {
    height: auto;
    padding-top: 120%;
  }
  &:hover {
    .caseStudy__inner {
      transition-delay: .05s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: translateY(-2.25rem);
    }
    .caseStudy__cta {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0s,opacity .2s cubic-bezier(.365,.005,.355,1);
    }
    img {
      width: 98%;
      height: 98%;
    }
    &:after {
      right: 1%;
      bottom: 1%;
      left: 1%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5;
    margin: auto;
    height: 18rem;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
    @include opacity(0.7);
    transition: all .2s cubic-bezier(.365,.005,.355,1);
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__inner {
    position: absolute;
    left: 1.875rem;
    bottom: 1.875rem;
    right: 1.875rem;
    z-index: 10;
    transition: transform .3s cubic-bezier(.645,.045,.355,1);
    @include bp(mlarge) {
      left: 3.125rem;
      bottom: 3.125rem;
      right: 3.125rem;
    }
    * {
      color: $white;
    }
  }
  &__heading {
      p {
          text-transform: uppercase;
          font-family: $quaternary-font;
          font-size: 0.9rem;
          font-weight: 400;
      }
      h3 {
          margin: 0;
          @include bp_max(mlarge) {
              font-size: 1.6em;
          }
      }
  }
  &__cta {
    position: absolute;
    top: 100%;
    left: 0;
    visibility: hidden;
    opacity: 0;
    margin-top: 1rem;
    font-family: $tertiary-font;
    transition: visibility 0s linear .2s,opacity .2s cubic-bezier(.365,.005,.355,1);
    will-change: opacity;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    max-width: none;
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: all .2s cubic-bezier(.365,.005,.355,1);
  }
  
  // XFactor image position
  &--3 {
      @include bp_max(smedium) {
      img {
          object-position: 0 12%;
      }
    }
  }
  &--4 {
      @include bp_max(smedium) {
      img {
          object-position: 0 81%;
      }
    }
  }
}
.slickButton {
  margin-top: 1rem;
  text-align: right;
  @include bp_max(smedium) {
    display: none;
  }
  /*.button {
      .opacity(0.4);
  }*/
}
  
  
.caseStudy-image {
  width: 1024px;
  margin: auto;
  @extend .shadow-style-1;
}