/* Banner / Slider
================================================== */
.banner {
  padding-top: 3rem;
  padding-bottom: 0;
  .row {
    position: relative;
  }
  &__title {
    position: absolute;
    z-index: 30;
    bottom: 0;
    @include bp(mlarge) {
      bottom: 4rem;
    }
    h1 {
      margin: 0;
      padding: 1.5rem 3rem;
      background-color: $off-white;
      font-family: $tertiary-font;
      font-style: italic;
      @include bp_max(mlarge) {
        font-size: 2.4rem;
      }
    }
  }
  .n2-section-smartslider {
    z-index: 20;
  }
  &--image {
    .banner__image {
      position: relative;
      z-index: 20;
      width: 100%;
      overflow: hidden;
      text-align: center;
      height: 340px;
      @include bp(mlarge) {
        height: 400px;
      }
      @include bp(xlarge) {
        height: 520px;
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      min-height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  &--video {
    height: 340px;
    @include bp(mlarge) {
      height: 400px;
    }
    @include bp(xlarge) {
      height: 520px;
    }
    video {
      z-index: 20;
    }
  }
  &--margin {
    padding-bottom: 6rem;
    &-small {
      padding-bottom: 3rem;
    }
  }
}
