/* Footer
================================================== */
footer {
    position: relative;
    overflow: hidden;
    padding: 6rem 0 3rem 0;
    .sub-heading {
        margin-bottom: 2rem;
    }
    .hexagon {
        width: 180px;
        margin: auto;
    }
    .telephone-number {
        margin-top: 1rem;
        p {
            font-family: $secondary-font;
            font-size: 1.2rem;
        }
    }
    .social {
        justify-content: center;
        margin-top: 2rem;
        svg {
            max-width: 32px;
            max-height: 32px;
            * {
                fill: $black;
            }
        }
        a {
            padding: 0 0.8em;
            &:hover {
                svg * {
                    fill: $secondary-colour;
                }
            }
        }
    }
    .nav {
        display: block;
        column-count: 2;
        margin-left: 0.2rem;
        margin-bottom: 4rem;
        @include bp(medium) {
            column-count: 4;
        }
        @include bp(large) {
            margin-bottom: 0;
            column-count: 2;
        }
        li {
            display: inline-block;
            width: 100%;
            text-align: left;
        }
        .mod-menu__sub {
            display: none !important;
        }
    }
    .address {
        margin-bottom: 4rem;
        ul {
            list-style: none;
            margin: 0 0 0 2.2rem;
            padding: 0;
        }
    }
}

.footer {
    &__top {
        margin-bottom: 6rem;
    }
    &__left {
        order: 3;
        @include bp(medium) {
            order: 2;
        }
        @include bp(large) {
            order: 1;
        }
    }
    &__center {
        order: 1;
        @include bp(medium) {
            order: 1;
        }
        @include bp(large) {
            order: 2;
        }
    }
    &__right {
        order: 2;
        @include bp(medium) {
            order: 3;
        }
    }
    &-contact {
        text-align: center;
        p {
            margin: 0;
        }
    }
    &__bottom {
        text-align: center;
        margin-bottom: 6rem;
        @include bp(large) {
            margin-bottom: 0;
        }
        p, a {
            display: inline-flex;
            margin: 1rem;
            font-size: 0.8rem;
        }
        a {
            color: $black;
            &:hover {
                color: $secondary-colour;
            }
        }
    }
    &-graphic {
        position: absolute;
        bottom: -6rem;
        right: 0;
        z-index: -1;
        width: 920px;
        min-width: 640px;
        @include bp(large) {
            bottom: -10rem;
        }
        @include bp(xxlarge) {
            bottom: 0;
        }
    }
}