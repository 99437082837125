/* Sections 
================================================== */
section {
  position: relative;
  padding: 6rem 0;
}
.section-no-overflow {
  overflow: hidden;
}

.full-width {
  padding: 6rem 0;

  &--margin-bottom {
    margin-bottom: 6rem;
  }

  &--white {
    background: $white;
  }

  &--black {
    background: $black;
    * {
      color: $white;
    }
  }

  &--grey {
    background: $grey;
  }

  &--narrow {
    padding: 4rem 0;
  }

  &--global {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background: $white;
    &:after {
      @include bp(large) {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 65%;
        background: url("#{$image-directory}international-map.jpg") 50% 50% /
          cover;
      }
    }
    .wf-column {
      position: relative;
      padding: 14rem 0 6rem;
      @include bp(large) {
        padding: 14rem 0;
      }
      &--left {
        position: relative;
        @include bp(large) {
          padding-right: 6rem;
        }
        * {
          position: relative;
          z-index: 20;
        }
        &::after {
          @include bp(large) {
            content: "";
            position: absolute;
            top: 0;
            right: -6rem;
            bottom: 0;
            z-index: 10;
            width: 100%;
            background: url("#{$image-directory}shape-bg.svg") 100% 50% / cover
              no-repeat;
          }
        }
      }
      &--right {
        min-height: 460px;
        @include bp_max(large) {
          background: url("#{$image-directory}international-map.jpg") 50% 50% /
            cover;
        }
      }
    }
  }

  &--map {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    background: $primary-colour;
    .wf-column {
      position: relative;
      padding: 0;
      &--left {
        position: relative;
        padding: 6rem 0;
        @include bp(large) {
          padding-right: 6rem;
        }
        * {
          position: relative;
          z-index: 20;
          color: $white;
          .sticky-heading p:before,
          .sub-heading p:before {
            background: $white;
          }
        }
        &::after {
          @include bp(large) {
            content: "";
            position: absolute;
            top: 0;
            right: -6rem;
            bottom: 0;
            z-index: 10;
            width: 100%;
            background: url("#{$image-directory}shape-bg-black.svg") 100% 50% /
              cover no-repeat;
          }
        }
      }
      &--right {
        @include bp_max(large) {
          display: none;
        }
      }
    }
    iframe {
      display: block;
      width: 100%;
      height: 480px;
      @include bp(large) {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        width: 65%;
        height: 100%;
      }
    }
    /*iframe {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: -0.9375rem;
            width: 105%;
            height: 100%;
            @include bp(large) {
                left: -30%;
                width: 140%;
            }
        }*/
  }
}

.map-keys {
  position: absolute;
  z-index: 20;
  right: -0.9375rem;
  bottom: 4.5rem;
  background: $off-white;
  @include bp(large) {
    bottom: 8rem;
  }
  &__wrap {
    display: flex;
    align-items: center;
  }
}
.map-key {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin: 0.5rem 2rem;
  img,
  p {
    display: inline-flex;
    margin: 0;
  }
  img {
    max-width: 16px;
    max-height: 26px;
    margin-right: 0.5rem;
  }
  p {
    font-size: 0.8rem;
    @include bp(large) {
      font-size: 0.9rem;
    }
  }
}

/* Clients Carousel
================================================== */
.our-clients {
  margin-bottom: -16rem;
  z-index: 20;
  #home & {
    padding-top: 0;
  }
  &__carousel {
    /*bottom: 3.5rem;
        .n2-ss-slider-pipeline {
            padding: 64px 0 !important;
            @include bp_max(mlarge) {
                --slide-width: 200px !important;
                --slide-side-margin: 16px !important;
            }
        }
        .n2-ss-preserve-size {
            @include bp_max(mlarge) {
                max-width: 200px !important;
            }
        }
        .n2-ss-slide {
            @include bp_max(mlarge) {
                width: 200px !important;
                height: 200px !important;
            }
        }*/
    .n2-ss-slider-pipeline {
      padding-bottom: 4rem;
    }
    .n2-ss-slide {
      overflow: visible !important;
      @extend .shadow-style-1;
    }
  }
}

/* Social feed 
================================================== */
#social-feed {
  position: relative;
  overflow: hidden;
  padding-top: 6rem;
  .sticky-heading {
    margin-bottom: 2rem;
  }
}
.social-feed {
  /*&__slider {
        .n2-ss-slider-pipeline {
            @include bp_max(mlarge) {
                --slide-width: 200px !important;
                --slide-side-margin: 16px !important;
            }
        }
        .n2-ss-preserve-size {
            @include bp_max(mlarge) {
                max-width: 200px !important;
            }
        }
        .n2-ss-slide {
            @include bp_max(mlarge) {
                width: 200px !important;
                height: 200px !important;
            }
        }
        .n2-ss-layer {
            @include bp_max(mlarge) {
                height: 200px !important;
            }
            @include bp_max(mlarge) { 
                font-size: 1rem !important;
            }
        }
    }*/
  &__heading {
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: -1;
    margin: 0;
    font-family: $secondary-font;
    font-size: 4.4rem;
    color: #f8f8f8;
    @include opacity(0.8);
    @include bp(medium) {
      right: -2rem;
      left: auto;
      font-size: 5rem;
    }
    @include bp(mlarge) {
      top: -4rem;
      font-size: 10rem;
    }
  }
}
.social-card {
  &:hover {
    .social-card__caption {
      & > div {
        @include opacity(1);
      }
    }
  }
  &__caption {
    height: 100% !important;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: rgba(102, 102, 102, 0.75);
      @include opacity(0);
      transition: all $global-transition !important;
      p {
        font-family: $secondary-font !important;
        font-weight: normal !important;
        text-transform: uppercase !important;
        font-size: 1rem !important;
      }
    }
  }
}

/* Services Slider
================================================== */
.services-slider {
  .n2-ss-slider-wrapper-inside {
    padding-bottom: 120px;
  }
  .n2-ss-slider-1 {
    z-index: 30 !important;
  }
  .n2-ss-slider-3 {
    display: block !important;
  }
  .n2-ss-section-main-content {
    padding: 0 !important;
  }
  &__left {
    padding: 2rem;
    background: $white;
    border-radius: 24px;
    -webkit-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 8px 8px 50px 0px rgba(0, 0, 0, 0.2);
    @include bp(mlarge) {
      padding: 4rem 6rem;
    }
  }
  &__right {
    @include bp(mlarge) {
      position: relative;
      right: 0;
      top: 0;
      margin-left: -5rem !important;
    }
  }
  .n2-ss-item-content {
    p {
      line-height: $base-line-height;
      font-size: 1rem;
    }
  }
  .list-style--three-columns {
    ul,
    ol {
      display: flex !important;
      flex-direction: column !important;
      flex-wrap: nowrap !important;
      @include bp(medium) {
        flex-direction: row !important;
        flex-wrap: wrap !important;
      }
      li {
        flex: 1;
        @extend .columns;
        @extend .small-12;
        font-size: 0.8rem !important;
        @include bp(medium) {
          flex: 0 0 50%;
          max-width: 50%;
        }
        @include bp(mlarge) {
          font-size: 1rem !important;
        }
        @include bp(large) {
          flex: 0 0 33.33333%;
          max-width: 33.33333%;
        }
      }
    }
  }
  .button {
    display: inline-flex !important;
    flex-direction: column;
    margin-bottom: 0 !important;
    padding: 1rem 2rem !important;
    background: transparent !important;
    border: 2px solid $black !important;
    color: $black !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    text-decoration: none !important;
    line-height: normal !important;
    font-family: $quaternary-font !important;
    text-transform: uppercase !important;
    outline: none;
    border-radius: 50px !important;
    transition: 600ms cubic-bezier(0.23, 1, 0.32, 1) !important;
    &:hover {
      background-color: $black !important;
      color: $secondary-colour !important;
    }
  }
  .nextend-thumbnail {
    height: 240px;
  }
  .nextend-thumbnail-inner {
    display: flex;
    align-items: flex-end;
  }
  .nextend-thumbnail-scroller {
    .n2-thumbnail-dot {
      background: transparent !important;
      .n2-ss-caption {
        transition: $global-transition;
        &:hover {
          background: rgb(116, 116, 116) !important;
          background: linear-gradient(
            0deg,
            rgba(116, 116, 116, 0.25) 0%,
            rgba(34, 34, 34, 1) 100%
          ) !important;
          & > div {
            color: $secondary-colour !important;
          }
        }
        & > div {
          display: flex;
          align-items: center;
          transition: $global-transition;
          svg {
            max-height: 48px;
            max-width: 72px;
            width: 100%;
            margin-right: 1rem;
          }
        }
      }
      &.n2-active {
        cursor: default !important;
        .n2-ss-caption {
          background: rgb(116, 116, 116) !important;
          background: linear-gradient(
            0deg,
            rgba(116, 116, 116, 0.75) 0%,
            rgba(34, 34, 34, 1) 100%
          ) !important;
          &:hover {
            & > div {
              color: $white !important;
            }
          }
          & > div {
            font-family: $tertiary-font !important;
            font-weight: 400 !important;
            font-style: italic !important;
          }
        }
      }
    }
  }
  @include bp_max(mlarge) {
    .n2-ss-preserve-size,
    .n2-ss-slider-controls {
      display: none !important;
    }
    [data-force-hidden],
    [data-force-hidden] * {
      visibility: visible !important;
    }
    .n2-ss-slider-1 {
      cursor: default !important;
    }
    .n2-ss-slider-4 {
      display: block !important;
    }
    .n2-ss-slide {
      display: block !important;
      transform: none !important;
      width: 100% !important;
      height: auto !important;
    }
    .n2-ss-layer,
    &__left,
    &__right {
      transform: none !important;
      transform-origin: unset !important;
      opacity: 1 !important;
    }
    &__image {
      height: 320px !important;
    }
  }
}

/* Lifecycle Section
================================================== */
.lifecylce {
  @include bp(large) {
    em {
      display: block;
    }
  }
}
.lifecycle-slider {
  @include bp_max(large) {
    margin-top: 2rem;
  }
}
.slide-overlay-gradient {
  @include bp_max(mlarge) {
    width: 220px !important;
  }
  &--left {
    background: rgb(34, 34, 34);
    background: linear-gradient(
      90deg,
      rgba(34, 34, 34, 1) 25%,
      rgba(34, 34, 34, 0) 100%
    );
  }
  &--right {
    left: auto !important;
    right: 0 !important;
    transform: none !important;
    background: rgb(34, 34, 34);
    background: linear-gradient(
      270deg,
      rgba(34, 34, 34, 1) 25%,
      rgba(34, 34, 34, 0) 100%
    );
  }
}
