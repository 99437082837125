/* Navigation
================================================== */
.nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  width: auto;
  margin: 0;
  padding: 0;

  li {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    list-style: none;
    @include bp_max(large) {
      width: auto;
      text-align: center;
    }
  }

  a {
    display: block;
    padding: 1rem 1.2rem;
    color: $black;
    font-size: 0.8rem;
    text-decoration: none;
    line-height: 1.8;
    text-transform: uppercase;
    font-family: $secondary-font;
    @include bp(xlarge) {
      padding: 1rem 2.2rem;
      font-size: 0.9rem;
    }
  }

  a:hover,
  .current > a,
  .active > a {
    color: $secondary-colour;
  }

  ul {
    display: none;
  }

  .parent {
    > a {
      position: relative;

      /*&:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: #000000 transparent transparent transparent;
      }*/
    }

    &:hover {
      ul {
        display: flex;
      }
    }

    ul {
      display: none;
      flex-flow: column nowrap;
      list-style: none;
      width: 100%;
      margin: 0;
      padding: 0;

      @include bp(large) {
        position: absolute;
        top: 100%;
        align-items: flex-start;
      }

      li {
        width: 100%;
      }
    }
  }

}



/* Main Nav
================================================== */
header {
  .nav {
    &__wrap {
      @include bp_max(large) {
        display: none;
      }
    }    
    .parent .mod-menu__sub {
      display: none !important;
    }
  }
}



/* Overlay Nav
================================================== */
$animation-duration: 1s;
$animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
.nav {
  &__overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 90;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    * {
      color: $white;
    }
    a {
      font-family: $secondary-font;
      text-transform: capitalize;
      color: $white;
      &:hover {
        color: $secondary-colour;
      }
    }
    .nav__wrap {
      position: relative;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
    }
  }
  &__header {
    position: absolute;
    top: 1rem;
    width: 100%;
    z-index: 30;
    .nav-button {
      top: 55px;
      //right: 64px;
      right: 0;
      margin-left: auto;
      .shrink & {
        top: 45px;
      }
      svg {
        * {
          transition: $global-transition;
        }
      }
      &:hover {
        svg {
          * {
            stroke: $secondary-colour;
          }
        }
      }
      p {
        color: $white;
      }
    }
  }
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    .row {
      width: 100%;
    }
  }
  &__overlay-main {
    display: block;
    li {
      text-align: left;
      a {
        padding: 0;
        font-size: 4rem;
        @include bp(mlarge) {
          font-size: 3.4rem;
        }
        @include bp(xlarge) {
          font-size: 4rem;
        }
        &.nav__item--active {
          color: $black !important;
          -webkit-text-stroke-width: 2px;
          -webkit-text-stroke-color: $secondary-colour;
        }
      }
      &.active, &.current {
        & > a {
          color: $white;
          &:hover {
            color: $secondary-colour;
          }
        }
      }
    }
    .mod-menu__sub {
        display: none !important;
    }
  }
  &__content {
    display: none;
    flex-wrap: wrap;
    justify-content: left;
    .button {
      border-color: $off-white;
      color: $off-white;
      font-family: $quaternary-font;
      text-transform: uppercase;
      font-size: 0.8rem;
      &:hover {
        background-color: $off-white;
        color: $primary-colour;
      }
    }
    &--active {
      @include bp(mlarge) {
        display: flex;
      }
    }
    &--services {
      li {
        text-align: left;
      }
      & > li {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0.5rem 2rem 0.5rem 0.5rem;
        @include bp(medium) {
          flex: 0 0 33.33%;
          max-width: 33.33%;
        }
        @include bp(large) {
          flex: 0 0 25%;
          max-width: 25%;
        }
      }
      a {
        padding: 0 0 1.5rem 0;
        font-size: 1rem;
        @include bp(large) {
          font-size: 1.25rem;
        }
      }
      .mod-menu__sub {
        margin-bottom: 1rem !important;
        @include bp(large) {
          display: block !important;
          position: static !important;
        }
        @include bp_max(large) {
          display: none !important;
        }
        @media screen and ( max-height: 900px ){
          display: none !important;
        }
        a {
          padding-top: 0.2rem;
          padding-bottom: 0.2rem;
          font-family: $primary-font;
          text-transform: uppercase;
          font-size: 0.7rem;
        }
      }
    }
    &--news {
      flex-direction: column;
      .newslist {
        .latestnews-items {
          -webkit-justify-content: left !important;
          -ms-flex-pack: left !important;
          justify-content: left !important;
        }
        .latestnews-item {
          margin-bottom: 0 !important;
          padding: 0 2rem 2rem 0 !important;
          &:hover {
            .link {
              a {
                background-color: $secondary-colour !important;
              }
              span {
                color: $primary-colour !important;
              }
            }
          }
          &:nth-of-type(2) {
            @include bp_max(large) {
              display: none !important;
            }
          }
          @include bp(mlarge) {
            flex: 0 0 100%;
            max-width: 100%;
          }
          @include bp(large) {
            flex: 0 0 50%;
            max-width: 50%;
          }
          @include bp(xlarge) {
            flex: 0 0 41.6666666667%;
            max-width: 41.6666666667%;
          }
          .news {
            background-color: $grey;
          }
          .detail_data {
            color: $primary-colour !important;
          }
        }
        .innerpicture {
          @media screen and ( max-height: 900px ){
            height: 160px;
          }
        }
        .newsintro {
          font-family: $primary-font;
          @include bp_max(large) {
            display: none !important;
          }
          @media screen and ( max-height: 900px ){
            display: none !important;
          }
        }
        .newstitle {
          @media screen and ( max-height: 900px ){
            font-size: 1.2rem;
          }
        }
        .link {
          a {
            background-color: $dark-grey;
            span {
              color: $off-white;
            }
          }
        }
      }
      .button {
        margin: 1rem 0 0 0;
      }
    }
    &--contact {
      a {
        text-transform: inherit;
      }
    }
  }

  &__background {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  &__row {
    height: 20vh;
    background: $black;
    will-change: width, height;
    animation-name: rowAnimateClose;
    animation-duration: $animation-duration;
    animation-delay: calc(400ms + (var(--animation-reverse-order) * 100ms));
    animation-fill-mode: both;
    animation-timing-function: $animation-timing-function;
    .nav-active & {
      animation-name: rowAnimateOpen;
      animation-delay: calc(var(--animation-order) * 100ms);
    }
  }
  &__left {
    display: flex;
    align-items: center;
    .nav-item {
      animation-name: fadeRightOut;
      animation-duration: $animation-duration;
      animation-fill-mode: both;
      animation-timing-function: $animation-timing-function;
      a {
        margin: 0 0 2.5rem 0;
        line-height: 100%;
      }
      // Services
       &.item-119 {
        animation-delay: 400ms;
      }
      // News
      &.item-120 {
        animation-delay: 300ms;
      }
      // About
      &.item-121 {
        animation-delay: 200ms;
      }
      // Contact
      &.item-122 {
        animation-delay: 100ms;
      }
      .nav-active & {
        animation-name: fadeRightIn;
        // Services
        &.item-119 {
          animation-delay: 100ms;
        }
        // News
        &.item-120 {
          animation-delay: 200ms;
        }
        // About
        &.item-121 {
          animation-delay: 300ms;
        }
        // Contact
        &.item-122 {
          animation-delay: 400ms;
        }
      }
    }
  }
  &__right {
    padding-left: 6rem;
  }
}

// Row background gird animation
@keyframes rowAnimateOpen {
  0% {
    width: 0;
  }
  
  100% {
    width: 100vw;
  }
}
@keyframes rowAnimateClose {
  0% {
    width: 100vw;
  }
  
  100% {
    width: 0;
  }
}

// Menu items fade in
@keyframes fadeRightIn {
  0% {
    @include opacity(0);
    transform: translate3d(-60px, 0, 0);
  }
  
  100% {
    @include opacity(1);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeRightOut {
  0% {
    @include opacity(1);
    transform: translate3d(0, 0, 0);
  }
  
  100% {
    @include opacity(0);
    transform: translate3d(-60px, 0, 0);
  }
}



/* Hamburger / Menu
================================================== */
.nav-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: visible;
  z-index: 1;
  margin: 0 0 0 1rem;
  padding: 1rem 0;
  background: transparent  !important;
  border: none !important;
  outline: 0;
  cursor: pointer;
  @include bp(large) {
    margin-left: 2rem;
  }
  

  &:hover {
    svg {
      .st1 {
        fill: $black !important;
      }
    }
  }

  &:before {
    display: none !important;
  }

  p {
    margin: 0;
    padding: 0 1rem 0 0;
    color: $body-font-color;
    font-family: $secondary-font;
    text-transform: uppercase;
    font-size: 1.2rem;
    @include bp(medium) {
      font-size: 1.4rem;
    }
  }

  svg {
    width: 22px;
    margin-right: 1rem;
    .st1 {
      transition: $global-transition !important;
    }
  }

}
